@import '../../index.scss';

.container {
	margin-top: 5rem;
	padding-top: 5rem;
	background-color: $third-color;
}

.extend {
	padding-bottom: 15rem;
	margin-bottom: -10rem;
}

.text {
	margin: 0 auto;
	padding: 0 1rem;
	max-width: $main-column-width;

	h1,
	h2 {
		color: $second-color;
	}
}
