.App {
	text-align: center;
	background-color: #282c34;
	color: #fff;
	min-height: 100vh;
}

.App-logo {
	height: 40vmin;
}

.App-header {
	background-color: #282c34;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-title {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-title h1 {
	font-size: 36pt;
	line-height: 2rem;
	font-weight: 600;
	/*margin-top: .5rem;
	margin-bottom: .5rem;*/
}

.App-link {
	color: #09d3ac;
}

.bg-dark .dropdown-menu .dropdown-item {
	color: #fff !important;
}

.title {
	margin: 0;
	padding: 0;
}

.subtitle {
	text-align: right;
}

.coverImage {
	width: 100%;
	object-fit: contain;
	height: 100%;
}

.Gallery {
	position: relative;
	overflow: hidden;
	white-space: nowrap;
}

.Gallery-wrapper {
	position: relative;
	height: 100%;
	width: 100%;
}

.slide {
	display: inline-block;
	height: 100%;
	width: 100%;
}

.arrow {
	height: 50px;
	width: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #555555;
	border-radius: 50%;
	cursor: pointer;
	transition: transform ease-in 0.1s;
}

.nextArrow {
	position: absolute;
	top: 50%;
	right: 25px;
	z-index: 999;
	color: #fff;
}

.backArrow {
	position: absolute;
	top: 50%;
	right: 25px;
	z-index: 999;
	color: #fff;
}

.fa-arrow-right:before,
.fa-arrow-left:before {
	color: #222;
}

.text {
	color: #fff;
}

.small {
	font-size: 8pt;
}

.games-listing {
	color: #fff;
	display: flex;
	flex-direction: column;
	text-align: center;
}

.listing-image {
	object-fit: contain;
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
}

/*Add background-image property in JS to set the image*/
.parallax {
	position: relative;
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

/*
.mits-parallax-offset {
	background-position: 300px -100px;
}

.dp-parallax-offset {
	background-position: -300px -100px;
}
*/

.bgimg-test-mits {
	background-image: url('Image/MadeInTheShade.png');
	min-height: 100%;
}

.bgimg-test-dp {
	background-image: url('Image/DualProcessors.png');
}

.footer {
	padding-top: 50px;
	padding-bottom: 50px;
}

.socials-container {
	margin: 0 auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.social-icon {
	height: 32px;
	margin: 5px;
}
