@import '../../../index.scss';

.buttonLink {
	&,
	&:link,
	&:visited {
		text-decoration: none;
		padding: 1.5rem 4rem;
		display: inline-block;
		border-radius: 10rem;
		transition: all 0.2s;
		position: relative;
		color: $black;
		border: none;
	}

	&:hover {
		transform: translateY(-3px);
		box-shadow: 0 1rem 2rem rgba($black, 0.2);

		&::after {
			transform: scaleX(1.4) scaleY(1.6);
			opacity: 0;
		}
	}

	&:active,
	&:focus {
		outline: none;
		transform: translateY(1px);
		box-shadow: 0 0.5rem 1rem rgba($black, 0.2);
	}

	&White {
		background-color: $white;
		color: $second-color;

		&::after {
			background-color: $white;
		}
	}

	&::after {
		content: '';
		display: inline-block;
		height: 100%;
		width: 100%;
		border-radius: 10rem;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		transition: all 0.4s;
	}

	&Animated {
		animation: moveInBottom 0.5s ease-out 0.75s;
		animation-fill-mode: backwards;
	}
}
