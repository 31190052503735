.container {
	padding: 0.4rem;
	margin: 0.5rem;

	border-radius: 5px;
	transition: all 0.1s ease-in-out;

	&:hover {
		background-color: #151515;
	}
}

.link {
	text-decoration: none;
	color: inherit;
}
